import fp from 'lodash/fp';
import moment from 'moment';
import {debounce} from './util';

import {url_for} from '../routes';

import {fetchedDirectory, fetchedUserInfo, loggedIn, loggedOut} from 'ducks/apiDuck';

const attrName = col => col.field.split('.').slice(1).join('.');

export class Api {
    constructor({
        apiRoot, 
        providerId, setProviderId,
        refreshToken, setRefreshToken, 
        directory, accessToken, 
        dispatch,
    }) {
        this._apiRoot = apiRoot;
        this._providerId = providerId;
        this._setProviderId = setProviderId;
        this._refreshToken = refreshToken;
        this._setRefreshToken = setRefreshToken;
        this.directory = directory;
        this._accessToken = accessToken;
        this._dispatch = dispatch;
    }

    bootstrap = debounce(async () => {
        if(!this.directory) {
            this.directory = await this.fetchJson(this._apiRoot);
            this._dispatch(fetchedDirectory(this.directory));
        }
        if(this._providerId && this._refreshToken.data && !this._accessToken) {
            await this.login({
                provider_id: this._providerId, 
                grant_type: 'refresh_token', 
                refresh_token: this._refreshToken.data
            });
        }
    })

    fetch = async (url, options={}) => {
        this._refreshIfNecessary();
        let o = this._updateFetchOptions(options);
        let resp = await fetch(url, o);
        if(!resp.ok) {
            let err = new Error(`Bad response ${resp}`)
            err.response = resp;
            throw(err);
        }
        return resp;
    }

    isAuthorized = () => !!this._accessToken

    login = debounce(async json => {
        let data = await this.fetchJson(
            this.directory.data.links['koozie.oauth.token_endpoint'],
            {method: 'POST', json}
        );
        this._processTokenData(data);
        let userInfo = await this.fetchJson(this.directory.data.links['koozie.oauth.userinfo_endpoint']);
        this._dispatch(fetchedUserInfo(userInfo));
    });

    logout = debounce(async () => {
        let redirect_uri = new URL(url_for('home'), window.location);
        let data = await this.fetchJson(
            this.directory.data.links['koozie.oauth.revoke_endpoint'], 
            {
                method: 'POST',
                json: {redirect_uri, provider_id: this._providerId, refresh_token: this._refreshToken.data}
            }
        );
        this._setProviderId(null);
        this._setRefreshToken({});
        this._accesssToken = null;
        this._dispatch(loggedOut());
        window.location = data.url;
    });

    fetchJson = async (url, options={}) => {
        let {onFetched, ...rest} = options;
        let resp = await(this.fetch(url, rest));
        let json = await resp.json();
        if(onFetched) {
            onFetched(json);
        }
        return json;
    }

    fetchJsonApi = async (url, options) => {
        let {fields={}, filter={}, page={}, sort=[], include=[], ...rest} = options;
        url = new URL(url);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([type, fieldNames]) => 
                url.searchParams.set(`fields[${type}]`, fieldNames.join(','))
            )
        ])(fields);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([k,v]) => url.searchParams.set(`filter[${k}]`, v))
        ])(filter);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([k,v]) => url.searchParams.set(`page[${k}]`, v))
        ])(page);
        if(sort.length > 0) {
            url.searchParams.set('sort', sort.join(','));
        }
        if(include.length > 0) {
            url.searchParams.set('include', include.join(','))
        }
        return this.fetchJson(url, rest);
    }

    fetchMaterialTable = async (url, options) => {
        let {
            filters, orderBy, orderDirection, page, pageSize, search, 
            totalCount, ...rest
        } = options;
        let jsonApiFilters = fp.pipe([
            fp.map(flt => {
                const k = attrName(flt.column);
                if(flt.operator === '=') {
                    return [k, '^' + flt.value]
                } else {
                    return null;
                }
            }),
            fp.filter(x => x !== null),
            fp.toPairs
        ])(filters);
        if(search) {
            jsonApiFilters['_q'] = search;
        }
        let jsonApiSort = [];
        if(orderDirection === 'desc') {
            jsonApiSort.push('-' + attrName(orderBy));
        } else if(orderDirection === 'asc') {
            jsonApiSort.push(attrName(orderBy));
        }

        let data = await this.fetchJsonApi(url, {
            filter: jsonApiFilters,
            page: {
                limit: pageSize, 
                offset: (page * pageSize)
            },
            sort: jsonApiSort,
            ...rest,
        });
        return {
            data: fp.map(row => ({data: row}), data.data), // prevent mutation of state
            totalCount: data.meta.total,
            page
        }
    }

    _refreshIfNecessary = async () => {
        let dirLinks = fp.get('data.links', this.directory);
        if(
            fp.isEmpty(this._accessToken)
            || fp.isEmpty(this._refreshToken)
            || fp.isEmpty(this._refreshToken.data)
            || fp.isEmpty(dirLinks)
        ) return;
        const safetyMargin = 30;
        let expires = moment(this._accessToken.expires).subtract(safetyMargin, 'seconds');
        let now = moment();
        if(expires.isAfter(now)) return;
        // Here we intentionally do *not* use this.fetch, this.login, or any other
        // method, in order to prevent an infinite # of _refreshIfNecessary calls
        let resp = await fetch(dirLinks['auth.token'], {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                grant_type: 'refresh_token', provider_id: this._providerId, refresh_token: this._refreshToken.data
            })
        });
        let data = await resp.json();
        this._processTokenData(data);
    }

    _processTokenData = data => {
        if(data.refresh_token) {
            this._refreshToken = {
                data: data.refresh_token,
                expires: data.refresh_token_expires ? moment().add(data.refresh_token_expires, 'seconds') : null
            }
            this._providerId = data.provider_id;
            this._setRefreshToken(this._refreshToken);
            this._setProviderId(this._providerId);
        }
        this._accessToken = {
            data: data.access_token,
            expires: moment().add(data.expires_in, 'seconds')
        }
        this._dispatch(loggedIn(data));
    }

    _updateFetchOptions = (options) => {
        let {
            headers = {},
            json = null,
            ...restOptions
        } = options;
        if(json !== null) {
            headers['content-type'] = 'application/json';
            restOptions['body'] = JSON.stringify(json)
        }
        if(this._providerId && this._accessToken) {
            headers['authorization'] = `bearer ${this._providerId}.${this._accessToken.data}`;
        }
        return {headers, ...restOptions};
    }
}
