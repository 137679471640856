import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {combineReducers} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {Elements as StripeProvider} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//import {MuiThemeProvider, CssBaseline} from '@material-ui/core';
import {SnackbarProvider} from 'notistack';

import ApiProvider from './components/Api';
import Router from './components/Router';
import { IntercomProvider } from './components/Intercom';
//import theme from './theme'
import apiDuck from './ducks/apiDuck';
import cartDuck from './ducks/cartDuck';
import listDuck from './ducks/listDuck';

import 'typeface-montserrat'

const API_ROOT = process.env.REACT_APP_API_ROOT;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY);
const store = configureStore({
    reducer: combineReducers({
        ...apiDuck,
        ...cartDuck,
        ...listDuck,
    })
});

export default function App() {
    return (
        <ReduxProvider store={store}>
            <SnackbarProvider maxSnacks={3} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
                <StripeProvider stripe={stripe}>
                    <ApiProvider apiRoot={API_ROOT}>
                        <IntercomProvider appId={INTERCOM_APP_ID}>
                            <Router />
                        </IntercomProvider>
                    </ApiProvider>
                </StripeProvider>
            </SnackbarProvider>
        </ReduxProvider>
    );
}
