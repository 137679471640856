import React from 'react';
import * as R from 'react-router-dom';
import {
    AppBar as MUIAppBar, IconButton, Toolbar, Link,
    makeStyles,
} from '@material-ui/core';

import {url_for} from '../routes';
import AuthInfo from './AuthInfo';
import CartInfo from './CartInfo';
import {useApi} from 'components/Api';
import * as I from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        margin: 0,
        "& > a": {
            color: 'white',
        },
    },
    subtitle: {
        color: "#5dd3a0"
    },
    logo: {
        height: 32,
        marginRight: 8,
    }

}));

export default function AppBar({onToggleDrawer, title}) {
    const classes = useStyles();
    const api = useApi();
    const link = api.isAuthorized() ? url_for('dashboard') : url_for('home');

    return (
        <MUIAppBar position="static" color="primary">
            <Toolbar>
                <IconButton onClick={onToggleDrawer} color="inherit">
                    <I.Menu/>
                </IconButton>
                <h5 className={classes.title}>
                    <Link component={R.Link} to={link}>
                        AlfredKnows
                    </Link> <span className={classes.subtitle}>Email Verification</span>
                </h5>
                <CartInfo/>
                <AuthInfo/>
            </Toolbar>
        </MUIAppBar>        
    )
}
    
