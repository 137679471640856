import React, { useState } from "react";
import fp from 'lodash/fp';
import {
    Grid,
    Link,
    Tabs,
    Tab,
    Checkbox,
    FormControlLabel,
    TextField as Input
} from "@material-ui/core";
import {useSnackbar} from 'notistack';

// styles
import useStyles from "./styles";

//components
import { Button, Typography } from "../../components/Wrappers";
import AboutPopover from 'components/AboutPopover';
import PricingPopover from 'components/PricingPopover';
import PrivacyTermsPopover from 'components/PrivacyTermsPopover';
import {Intercom} from 'components/Intercom';

import {useApi} from 'components/Api';
import {history, url_for} from 'routes';


function LoginForm({setForgot}) {
    var classes = useStyles();
    const api = useApi();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {enqueueSnackbar} = useSnackbar();
    const handleSubmit = async ev => {
        ev.preventDefault();
        try {
            await api.login({
                provider_id: 'alfred',
                grant_type: 'password',
                username: email,
                password
            });
            console.log('Logged in ok')
            history.push(url_for('dashboard'))
        } catch(e) {
            if(fp.get('response.status', e) === 403) {
                enqueueSnackbar('Invalid login', {variant: 'error'})
            } else {
                console.log(e.response);
                enqueueSnackbar('Unknown error', {variant: 'error'})
            }
        }

    };
    return (
        <form onSubmit={handleSubmit}>
            <Input 
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                margin="normal"
                placeholder="Email address"
                fullWidth
            />
            <Input 
                value={password}
                onChange={ev => setPassword(ev.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
            />
            <div className={classes.formButtons}>
                <Button
                    type="submit"
                    disabled={
                        email.length === 0 || password.length === 0
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Login
                </Button>
                <Button
                    color="primary"
                    size="large"
                    onClick={() => setForgot(true)}
                    className={classes.forgetButton}
                >
                    Forgot Password?
                </Button>
            </div>
        </form>
    )
}

function ForgotPasswordForm({setForgot}) {
    const api = useApi();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    const [email, setEmail] = useState('');
    const handleSubmit = async ev => {
        ev.preventDefault();
        console.log('Reset password', {email})
        try {
            let resp = await api.fetchJson(
                api.directory.data.links['auth.password-reset-request'], 
                {
                    method: 'POST',
                    json: {data: {
                        type: 'Email',
                        attributes: {id: email}
                    }}
                }
            );
            enqueueSnackbar('Password reset request sent')
            console.log('reset got', resp);
        } catch(e) {
            console.error('Error', e, e.response)
            if(fp.get('response.status', e) === 404) {
                enqueueSnackbar(
                    "Sorry, we couldn't find an account with that email address", {variant: 'error'})
            } else {
                enqueueSnackbar('Unknown error', {variant: 'error'})
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Input 
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                margin="normal"
                placeholder="Email address"
                fullWidth
            />
            <div className={classes.formButtons}>
                <Button
                    type="submit"
                    disabled={
                        email.length === 0
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Send
                </Button>
                <Button
                    color="primary"
                    size="large"
                    onClick={() => setForgot(false)}
                    className={classes.forgetButton}
                >
                    Back to login
                </Button>
            </div>
        </form>
    );
}

function SignupForm({setForgot, onDisplayTerms}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const [email, setEmail] = useState('');
    const [tos, setTos] = useState(false);
    const handleSubmit = async ev => {
        ev.preventDefault();
        try {
            await api.fetchJson(api.directory.data.links['auth.signup'], {
                method: 'POST',
                json: {data: {
                    type: 'User',
                    attributes: {email}
                }}
            });
            enqueueSnackbar("Please check your email for instructions on completing your signup", {variant: 'success'})
        } catch(err) {
            if(fp.get('response.status', err) === 409) {
                enqueueSnackbar('That address already has an account on our system.', {variant: 'error'})
            } else {
                console.error(err);
                enqueueSnackbar('Unknown error', {variant: 'error'})
            }
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <Input 
                value={email}
                onChange={ev => setEmail(ev.target.value)}
                margin="normal"
                placeholder="Email address"
                fullWidth
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={tos}
                        onChange={() => setTos(!tos)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label={<span>
                    I agree and accept 
                    the <Link variant="link" onClick={onDisplayTerms}>Terms of Use and Privacy Policy</Link>
                </span>}
            />
            <Button
                type="submit"
                disabled={
                    !tos || email.length === 0
                }
                variant="contained"
                color="primary"
                size="large"
                fullWidth
            >
                Create Your Account
            </Button>
        </form>
    )
}

export default function Login(props) {
    const classes = useStyles();

    const [activeTabId, setActiveTabId] = useState(0);
    const [forgot, setForgot] = useState(false);
    const [pricingOpen, setPricingOpen] = useState(false);
    const [aboutOpen, setAboutOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);

    return (
        <>
            <Intercom/>
            <AboutPopover open={aboutOpen} onClose={() => setAboutOpen(false)}/>
            <PrivacyTermsPopover open={termsOpen} onClose={() => setTermsOpen(false)}/>
            <PricingPopover open={pricingOpen} onClose={() => setPricingOpen(false)}/>
            <Grid container className={classes.container}>
                <div className={classes.logotypeContainer}>
                    <Tabs value={0} indicatorColor="primary">
                        <Tab label="Home" classes={{ root: classes.logoTypeTab }} />
                        <Tab label="Pricing" classes={{ root: classes.logoTypeTab }} 
                            onClick={() => setPricingOpen(true)}
                        />
                        <Tab label="About" classes={{ root: classes.logoTypeTab }} 
                            onClick={() => setAboutOpen(true)}
                        />
                    </Tabs>
                    <div style={{flexGrow: 1}}/>
                    <img src="/img/logo-rf.png" alt="logo" className={classes.logotypeImage} />
                    <Typography className={classes.logotypeText}>Email Verification</Typography>
                    <div style={{flexGrow: 1}}/>
                </div>
                <div className={classes.formContainer}>
                    <Tabs
                        value={activeTabId}
                        onChange={(e, id) => setActiveTabId(id)}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Login" classes={{ root: classes.tab }} />
                        <Tab label="New User" classes={{ root: classes.tab }} />
                    </Tabs>
                    <div style={{flexGrow: 1}}/>
                    <div className={classes.form}>
                        {activeTabId  === 0 
                            ? (
                                (forgot ? <ForgotPasswordForm setForgot={setForgot}/> : <LoginForm setForgot={setForgot}/>)
                            ) : <SignupForm onDisplayTerms={() => setTermsOpen(true)} />
                        }
                    </div>
                    <div style={{flexGrow: 1}}/>
                    <div className={classes.form}>
                        <p>
                            &copy; 2020 All Rights Reserved. AlfredKnows &reg; is 
                            a registered trademark of Email Industries. 
                            <Link onClick={() => setTermsOpen(true)}>Privacy & Terms</Link>
                        </p>
                    </div>
                </div>
            </Grid>
        </>
    );
}
