import React, {useEffect, useState} from 'react';

import {useApi} from 'components/Api';
import {useQuery, history} from 'routes';
import BasePage from 'components/BasePage';

export default function CallbackPage() {
    const [init, setInit] = useState();
    const q = useQuery();
    const api = useApi();
    const [content, setContent] = useState(null);

    const code = q.get('code');
    q.get('state');
    const next = q.get('next');
    
    useEffect(() => {
        async function main() {
            try {
                await api.login({
                    grant_type: 'authorization_code',
                    code
                });
                history.push(next);
            } catch(e) {
                setContent(`Error in callback: ${e}`);
            }
        }
        if(!init) {
            setInit(true);
            main();
        }
    }, [api, init, setInit, code, next]);
    return <BasePage title="Logging you in...">{content}</BasePage>
}
