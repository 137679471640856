import fp from 'lodash/fp';
import {defineAction} from 'redux-define';
import {createAction, handleActions} from 'redux-actions';

export const NAME = 'list';

// actions
export const ADD = defineAction('ADD', NAME);
export const DEL = defineAction('DEL', NAME);
export const UPDATE = defineAction('UPDATE', NAME);
export const REFRESH = defineAction('REFRESH', NAME);

// action creators
export const listAdded = createAction(ADD.ACTION);
export const listUpdated = createAction(ADD.ACTION);
export const listDeleted = createAction(DEL.ACTION);
export const listsRefreshed = createAction(REFRESH.ACTION);

// selectors
export const selectLists = state => state[NAME];

const initialState = {};

export default {
    [NAME]: handleActions({
        [ADD.ACTION]: (state, {payload}) => ({
            ...state,
            [payload.id]: payload
        }),
        [DEL.ACTION]: (state, {payload}) => fp.omit(payload.id),
        [REFRESH.ACTION]: (state, {payload}) => payload.data,
    }, initialState)
}
