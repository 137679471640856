import React, {useEffect, useState} from 'react';

import {useApi} from 'components/Api';
import {useQuery, history, url_for} from 'routes';
import BasePage from 'components/BasePage';

export default function VerifyEmailPage() {
    const [init, setInit] = useState();
    const q = useQuery();
    const api = useApi();
    const [content, setContent] = useState(null);

    const token = q.get('token');
    
    useEffect(() => {
        async function main() {
            try {
                let resp = await api.fetchJson(api.directory.data.links['auth.email-verification'], {
                    method: 'POST',
                    json: {data: {type: 'Email', attributes: {token}}}
                });
                await api.login({
                    grant_type: 'refresh_token', provider_id: resp.provider_id, refresh_token: resp.refresh_token
                });
                history.push(url_for('dashboard'))
            } catch(e) {
                setContent(`Error in callback: ${e}`);
            }
        }
        if(!init) {
            setInit(true);
            main();
        }
    }, [api, init, setInit, token]);
    return <BasePage title="Logging you in...">{content}</BasePage>
}
