import {useState, useCallback, useEffect} from 'react';

export const debounce = f => {
    let cur = null;
    return async (...args) => {
        if(cur) {
            let result = await cur;
            return result;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    }
}

export const useAsyncMemo = (f, deps=[]) => {
    const [value, setValue] = useState();
    useEffect(
        () => {f().then(setValue)},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    )
    return value;
}

export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const svalue = storage.getItem(key);
            return svalue ? JSON.parse(svalue) : initialValue;
        } catch(error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    })

    const setValue = useCallback(
        value => {
            try {
                const valueToStore = (
                    value instanceof Function
                    ? value(storedValue)
                    : value
                );
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch(error) {
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue, setStoredValue]
    )
    return [storedValue, setValue];
}

