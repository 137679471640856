import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {useSnackbar} from 'notistack';

import { Button, Tooltip, makeStyles } from '@material-ui/core';

import {useApi} from 'components/Api';
import {selectCart, cartUpdated} from 'ducks/cartDuck';

const useStyles = makeStyles(theme => ({
    errorButton: {
        backgroundColor: '#f9c255',
    },
}));


export default function ValidationColumn({list, onChanged, inCart, setDetailList}) {
    const api = useApi();
    const dispatch = useDispatch();
    const classes = useStyles();
    const cart = useSelector(selectCart);
    const {enqueueSnackbar} = useSnackbar();
    const status = fp.get('attributes.status', list);

    const addToCart = async () => {
        let cartItems = [
            ...fp.get('attributes.line_items', cart.data),
            {list_id: list.id}
        ]
        let data = fp.set(
            'attributes.line_items', cartItems, cart.data
        )
        let newCart = await api.fetchJson(cart.links.self, {
            method: 'PATCH',
            json: {data}
        })
        dispatch(cartUpdated(newCart))
        enqueueSnackbar('Added list to cart', {variant: 'success'})
    }

    const removeFromCart = async () => {
        let cartItems = fp.remove(
            li => li.list_id === list.id,
            fp.get('attributes.line_items', cart.data)
        );
        let data = fp.set(
            'attributes.line_items', cartItems, cart.data
        )
        let newCart = await api.fetchJson(cart.links.self, {
            method: 'PATCH',
            json: {data}
        })
        dispatch(cartUpdated(newCart))
        enqueueSnackbar('Removed list from cart')
    }

    const refreshStatus = async() => {
        let lst = await api.fetchJson(list.links.self);
        onChanged && onChanged(lst);
    }

    if(fp.get('attributes.num_emails', list) === 0) {
        return (
            <Tooltip
                arrow="true"
                color="secondary"
                placement="left"
                title="No emails found! If list is in CSV or TXT format, use (i) icon to update email column"
            >
                <Button variant="contained" className={classes.errorButton} 
                    onClick={() => setDetailList(list)}
                >
                    Error
                </Button>
            </Tooltip>
        )
    } else if(inCart(list)) {
        return <Button variant="contained" color="secondary" onClick={removeFromCart}>
            Added to cart
        </Button>
    } else if(status === "new") {
        return <Button variant="contained" color="secondary" onClick={addToCart}>
            Add to cart
        </Button>
    } else if(status === 'completed') {
        return <Button href={fp.get('links.download_result', list)} variant="contained" color="primary" >
            Download
        </Button>
    } else {
        return <Button variant="contained" color="primary" onClick={refreshStatus}>{status}</Button>
    }

}

