import React from 'react';
import ReactMarkdown from 'react-markdown';

import Popover from 'components/Popover';

const terms = `
# Terms of Use

Please read these Terms carefully. By using AlfredKnows or signing up for an account or using an account we have set up for you, you’re agreeing to these Terms, which will result in a legal agreement between you and Indiemark (“Agreement”).

We’ll start with the basics, including a few definitions that should help you understand these Terms.

AlfredKnows (“AlfredKnows”, “Indiemark,” “Email Industries”, “we,” or “us”) among other things is an online platform (the “Service”) offered through the URL [app.alfredknows.com](https://app.alfredknows.com/) (we’ll refer to it as the “Website(s)”) that allows you to, among other things, supply Vetting Data through the app that helps you determine the likelihood of an electronic message being delivered from one email inbox to another.

“Vetting Data” is defined as any electronic data or information submitted by you to the vetting Service by way of the web app or its API Endpoints. Vetting Data is solely used for the purpose of providing the Services. You own the Vetting Data. We may maintain copies of Vetting Data in order to provide activity monitoring for inbox deliverability. We automatically delete and/or destroy such data after thirty (30) days.

Indiemark, the company that owns AlfredKnows is a South Carolina limited liability company whose legal name is Indiemark LLC. Indiemark has employees, independent contractors, and representatives (“our Team”). As a customer of the Service or a representative of an entity that’s a customer of the Service, you’re a “Member” according to this Agreement (or “you”).

These Terms of Use (“Terms”) define the terms and conditions under which you’re allowed to use the Service in accordance with the Agreement, and how we’ll treat your account while you’re a Member. If you don’t agree to these Terms, you must immediately discontinue your use of the Service(s).
If you have any questions about our Terms, feel free to contact us. 

## Account

An account is any gated information which requires a username and password in order to access the information. Access to this information may be free (on a trial basis) and/or paid for on an “Pay As You Go” basis or Monthly Plan. Depending on the Service.

**1. Eligibility**
In order to use the Service, you must:
1. be at least eighteen (18) years old and able to enter into contracts;
2. agree to these Terms;
3. provide true, complete, and up-to-date contact and billing information; and
4. not be based in any country that is subject to a U.S. government embargo, or that has been designated by the U.S. government as a “terrorist-supporting” country.

By using the Service, you represent and warrant that you meet all the requirements listed above, and that you won’t use the Service in a way that violates any laws or regulations. Note that by representing and warranting, you are making a legally enforceable promise.

Indiemark may refuse service, close accounts of any users, and change eligibility requirements at any time.

**2. Term**
When you first sign up for or use the Service and agree to these Terms, the Agreement between you and Indiemark is formed, and the term of the Agreement (the “Term”) will begin. The Term will continue for as long as you have an account or until you or we terminate the Agreement in accordance with these Terms, whichever happens first. If you sign up for the Service on behalf of a company or other entity, you represent and warrant that you have the authority to accept these Terms and enter into the Agreement on its behalf.

**3. Closing Your Account**
You or Indiemark may terminate the Agreement at any time and for any reason by terminating your Indiemark account or giving notice to the other party. We may suspend the Service to you at any time, with or without cause. If we terminate your account without cause, and your account is a paid account, we’ll refund a prorated portion of your monthly prepayment. We won’t refund or reimburse you in any other situation, including if your account is suspended or terminated for cause, like a breach or any violation of the Agreement. If your account is inactive for 24 or more months, we may terminate the account.

**4. Changes**
We may change any of the Terms by posting revised Terms of Use on our Website and/or by notifying you of the new Terms by sending an email to the last email address you gave us or displaying prominent notice within the Service. Unless you terminate your account within 10 days, the new Terms will be effective immediately and apply to any continued or new use of the Service. We may change the Website, the Service, or any features of the Service at any time, and we may discontinue the Website, the Service or any features of the Service at any time.

**5. Account and Password**
You’re responsible for keeping your account name and password confidential. You’re also responsible for any account that you have access to and any activity occurring in such account (other than activity that Indiemark is directly responsible for that isn’t performed in accordance with your instructions), whether or not you authorized that activity. You’ll immediately notify us of any unauthorized access or use of your accounts. We’re not responsible for any losses due to stolen or hacked passwords that are caused by or result from your negligence. We have the right to update any of your contact information in your account for billing purposes. In addition, you represent and warrant that all information you provide to us when you establish an account, and when you access and use the Service, is and will remain complete and accurate.

## Payment

6. Pay-As-You-Go

When submitting your Vetting Data to our Service, pricing is calculated by volume upon upload of your data. Payment is made and then Vetting Data is run through the Service.

7. Monthly Plans
When you sign up for a Monthly Plan, you agree to recurring billing. Billing occurs on the same day each month, based on the date that you started the Monthly Plan. Billing for Monthly Plans may be paused, and you may choose to close your account permanently at any time. Our charges for Monthly Plans are posted on our Website and may be changed from time to time. If any part of a month is included in the Term, then payment is due for the full month. Payments are due for any month on the same or closest date to the day you made your first monthly payment (the “Pay Date”). 

8. Credit Cards 
Pay as you go accounts are charged as the Service is accessed and credit card information is not stored. For monthly plans, as long as you’re a Member with a paid account or otherwise have an outstanding balance with us, you’ll provide us with valid credit card information or active PayPal subscription and authorize us to deduct the monthly charges against that credit card or PayPal account. You’ll replace the information for any credit card that expires with information for a valid one. If your credit card is automatically replaced with a new card by a payment processor, you acknowledge and agree that we’re authorized to deduct any charges on your account against the new credit card. Anyone using a credit card represents and warrants that they are authorized to use that credit card, and that any and all charges may be billed to that credit card and won’t be rejected. If we’re unable to process your credit card order, we’ll try to contact you by email and suspend your account until your payment can be processed.

**9. Refunds**
We’ll give you a refund for a prepaid month if we stop providing the Service and terminate your account without cause. You won’t be entitled to a refund from us under any other circumstances. We may, at our sole discretion, offer a refund if a Member requests one.

**10. Fee Changes**
We may change our fees at any time by posting a new pricing structure to our Website and/or sending you a notification by email. Quoted fees don’t include sales or other transaction-based taxes of any kind.

## Rights

**11. Proprietary Rights Owned by Us**
You will respect our proprietary rights in the Website and the data or database used to provide the Service (proprietary rights include, but aren’t limited to, patents, trademarks, service marks, trade secrets, copyrights, and other intellectual property). 

**12. Proprietary Rights Owned by You**
You represent and warrant that you either own or have permission to use all the data, and information (including your personal information and the personal information of others) you submit to us in the course of using the Service (“Data”). You retain ownership of the Data that you upload to the Service. We may only use your Data (including any personal information therein) as described in these Terms.

## Rules and Abuse

13. Compliance with Laws
You represent and warrant that your use of the Service will comply with all applicable laws and regulations. You’re responsible for determining whether the Service is suitable for you to use in light of your obligations under any regulations like HIPAA, GLB, EU data privacy laws (including the General Data Protection Regulation) (collectively, “EU Data Privacy Laws”), United States export control laws and regulations and economic sanctions laws and regulations (“U.S. Export Control Laws and Regulations”), or other applicable laws. If you’re subject to regulations (like HIPAA) and you use the Service, then we won’t be liable if the Service doesn’t meet those requirements. You may not use the Service for any unlawful or discriminatory activities, including acts prohibited by the Federal Trade Commission Act, Fair Credit Reporting Act, Equal Credit Opportunity Act, Children’s Online Privacy Protection Act, or other laws that apply to commerce.

You agree to indemnify and hold us harmless from any losses, including all legal fees and expenses, that result from your breach of this section.

## Liability

**14. No Warranties
**To the maximum extent permitted by law, we provide the Website and the Service as-is. This means that, except as expressly stated in these Terms, we don’t provide warranties, conditions, or undertakings of any kind in relation to the Website and/or Service, either express or implied. This includes, but isn’t limited to, warranties of merchant-ability and fitness for a particular purpose, which are to the fullest extent permitted by law, excluded from the Agreement. Since Members use the Service for a variety of reasons, we can’t guarantee that it’ll meet your specific needs.

**15. Indemnity
**You agree to indemnify and hold us and our Team harmless from any losses, including legal fees and expenses that directly or indirectly result from any claims you make that aren’t allowed under these Terms due to a “Limitation of Liability” or other provision. (Indemnity is an agreement to compensate someone for a loss.) You also agree to indemnify and hold us harmless from any losses, including legal fees and expenses, that directly or indirectly result from (a) your Vetting Data, (b) your use of the Service, (c) your violation of any laws or regulations, (d) third-party claims that you or someone using your password did something that, if true, would violate any of these Terms, (e) any misrepresentations made by you, or (f) a breach of any representations or warranties you’ve made to us.

16. Legal Fees and Expenses
If we file an action against you claiming you breached these Terms and we prevail, we’re entitled to recover all reasonable legal fees, expenses, and any damages or other relief we may be awarded.

**17. Equitable Relief**
If you violate these Terms, then we may seek injunctive relief (meaning we may request a court order to stop you) or other equitable relief.

**18. Subpoena Fees**
If we must provide information in response to a subpoena, court order, or other legal, governmental, or regulatory inquiry related to your account, then we may charge you for our costs. These fees may include attorney and employee time spent retrieving the records, preparing documents, and participating in a deposition.

**19. Disclaimers**
We and our Team aren’t responsible for the behavior of any third parties, linked websites, or other Members.

## Fine Print

**20. Assignments**
You may not assign any of your rights under this agreement to anyone else. We may assign our rights to any other individual or entity at our discretion.

**21. Choice of Law**
The State of South Carolina’s laws, except for conflict of laws rules, will apply to any dispute related to the Agreement or the Service. Any dispute related to the Agreement or the Service itself will be decided by the state and federal courts in Aiken County, South Carolina, and each party will be subject to the jurisdiction of those courts.

**22. Force Majeure**
We won’t be held liable for any delays or failure in performance of any part of the Service, from any cause beyond our control. This includes, but is not limited to, acts of god, changes to law or regulations, embargoes, war, terroristic acts, riots, fires, earthquakes, floods, power blackouts, unusually severe weather conditions, and acts of hackers, or third-party internet service providers.

**23. Survivability**
Even if this Agreement is terminated, the following sections will continue to apply: Proprietary Rights Owned by Us, Proprietary Rights Owned by You, Compliance with Laws, Limitation of Liability, No Warranties, Indemnity, Choice of Law, Severability, and Entire Agreement.

**24. Severability**
If it turns out that a section of these Terms isn’t enforceable, then that section will be removed or edited as little as required, and the rest of the Agreement will still be valid.

**25. Interpretation**
The headers and sidebar text are provided only to make these Terms easier to read and understand. The fact that we wrote these Terms won’t affect the way the Agreement is interpreted.

**26. Amendments and Waiver**
Amendments or changes to the Agreement won’t be effective until we post revised Terms on the Website. If we don’t immediately act on a violation of these Terms, we’re not giving up any rights under the Terms, and we may still take action at some point.

**27. No Changes in Terms at Request of Member**
Because we have so many Members, we can’t change these Terms for any one Member or group.

**28. Further Actions**
You’ll provide all documents and take any actions necessary to meet your obligations under these Terms.

**29. Notification of Security Breach**
In the event of a security breach that may affect you or anyone on your lists (each a “List”), we’ll notify you of the breach once we have determined, in our discretion, that it occurred and will provide a description of what happened. If we determine, and notify you, that you need to forward all or part of that information to anyone on your Lists, you’ll promptly do so.

**20. Notices**
Any notice to you will be effective when we send it to the last email or physical address you gave us or when posted on our Website. Any notice to us will be effective when delivered to us along with a copy to our legal counsel.

**31. Entire Agreement**
The Agreement makes up the entire agreement between us in relation to its subject matter and supersede all prior agreements, representations, and understandings. 

# Privacy Policy

**1. Introduction to Our Services**

Indiemark LLC, the owner and operator of this website, provide to clients a wide variety of risk assessment, data enhancements and analytic services, which help our clients and the platforms and partners they work with to provide safer, more compliant and more relevant marketing communications.

Most of the information that we handle is not collected on this website, but rather through relationships we have with other companies such as data suppliers, business partners and our clients. We nonetheless provide this Privacy Policy as a service to consumers and others who want to learn more about the data we handle and how we use it.

California consumers interested in initiating a "Right to Know", "Opt-out", or "Data Deletion" request per the California Consumer Privacy Act of 2018, please see the section below titled "[Data Requests for California Residents](https://www.blackbox.email/privacy-policy#ccpa)".

If you are interested particularly in the information we collect on this website, please go to the section titled "[Data Collected On Our Website](https://www.blackbox.email/privacy-policy#website)".

**2. The Types of Data That We Obtain and Use**

Indiemark receives data from, and handles data on behalf of, a variety of consumer-facing companies, applications and services, business-facing companies, and data suppliers. We also may obtain data through public sources. Some of the data we receive, use and make available is Personally Identifiable Information ("PII"). PII includes, for instance, name, postal address, telephone number or email address.

We also collect certain information when you visit this website, as described in the below section titled "Data Collected On Our Website".

**3. Our Services, and How We Use Personally Identifiable Information**

We use and make available PII for various purposes. For instance,

We help email service providers, brands and agencies to preemptively identify and score email lists, email addresses and clients that may be harmful to their mailing reputations.

We provide mailbox providers and threat intelligence companies with enhancements services such as risk scoring, record lookups, anti-abuse and anti-fraud services, and other analytics.

We may use PII to help other data platforms perform cross-channel analysis and identification. These platforms may de-identify PII (such as by creating a “hashed” version of an email address, or another associated identifier) in order to identify consumers through online display media.

**5. How We Work with and Share Data with Third Parties**

Indiemark may share data, including PII, as follows:

Clients: We may share data, including PII, with our clients or to help us to perform any of the services described in this Privacy Policy. We may do so, for instance, to identify abusive message senders, risky lists or fraudulent data suppliers for analytical purposes, to assess risk or identify consumers across multiple marketing channels.

Partners: We may share data, including PII, with business and data partners (such as business partners who provide to marketers their own applications or data services) or with clients, to help provide more tailored analytical or deliverability services.

Corporate Transfers: If Indiemark or its significant assets are purchased by another entity, our data will likely be transferred to that entity, including for due diligence prior to any such potential purchase.

Parents and Subsidiaries: We may share some or all of the data in our possession with affiliated or subsidiary brands which include, but are not limited to, Alfred Knows.

As Required by Law or to Protect Any Party: Indiemark may disclose data if we believe in good faith that such disclosure is necessary to (a) comply with relevant laws or to respond to subpoenas or warrants served on us; or (b) to protect or otherwise defend the rights, property or safety of Indiemark or any other party.

**7. Data Requests for California Residents**

Indiemark complies with the California Consumer Privacy Act of 2018 (the "CCPA") and other California privacy laws, and it applies solely to "consumers" as that term is defined in the CCPA. Any terms defined in the CCPA have the same meaning when used in this statement.

Only a consumer or a person registered with the California Secretary of State that a consumer authorizes to act on a consumer’s behalf, may make a verifiable consumer request related to a consumer’s personal information. A consumer may also make a verifiable consumer request on behalf of that consumer's minor child.

We cannot respond to a consumer's request or provide a consumer with personal information if we cannot verify the consumer’s identity or authority to make the request and confirm the personal information relates to that consumer. Making a verifiable consumer request does not require a consumer to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.

**Data Deletion Requests ("Delete My Information") **

We may deny a consumer’s deletion request if retaining the information is necessary for us or our service providers or customers to; (a) complete the transaction for which we collected the personal information, provide a good or service that a consumer requested, take actions reasonably anticipated within the context of our ongoing business relationship with that consumer, or otherwise perform our contract with that consumer; (b) detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities; (c) debug products to identify and repair errors that impair existing intended functionality; (d) Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law; (e) comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.); (f) Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if a consumer previously provided informed consent; (g) enable solely internal uses that are reasonably aligned with consumer expectations based on a consumer’s relationship with us; (h) Comply with a legal obligation; )(i) make other internal and lawful uses of that information that are compatible with the context in which a consumer provided it.

To initiate a data deletion request, call us at (706) 426-1970 or email us at [*privacy@emailindustries.com*](mailto:privacy@emailindustries.com). If you do so, please include the following information: (a) email address; (b) first and last name; (c) current postal mailing address.

Once we receive and confirm verifiable consumer opt-out requests, we will no longer sell that consumer’s personal information, unless an exception applies, and direct our customers to do the same.

**Opt-out Requests ("Do Not Sell My Information")**
California residents have the right to opt out of the sale of their personal information. To initiate an opt-out request, call us at (706) 426-1970 or email us at [*privacy@emailindustries.com*](mailto:privacy@emailindustries.com). If you do so, please include the following information: (a) email address; (b) first and last name; (c) current postal mailing address.

Once we receive and confirm verifiable consumer opt-out requests, we will no longer sell that consumer’s personal information, unless an exception applies, and direct our customers to do the same.

**Requesting Your Information ("Right to Know") **

The California Consumer Privacy Act (CCPA) further gave California residents the right to know what PII a business has collected on them.

A consumer may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must include: a) provide sufficient information that allows Indiemark to reasonably verify a consumer is the person about whom we collected personal information or an authorized representative; b) describe a consumer’s request with sufficient detail that allows Indiemark to properly understand, evaluate, and respond to it.

To initiate a right to know and portability request, call us at (706) 426-1970 or email us at [*privacy@emailindustries.com*](mailto:privacy@emailindustries.com). If you do so, please include the following information: (a) email address; (b) first and last name; (c) current postal mailing address.

Indiemark typically collects first name, last name, postal address, email address, IP address, date for birth, phone, email activity, email risk score and modeled inferences.

Once we receive and confirm verifiable consumer "Right to Know" request, we will provide you with; (a) the categories of personal information it has collected about you; (b) the categories of sources from which the personal information is collected; (c) the business or commercial purpose for collecting or selling personal information; (d) the categories of third parties with whom the business shares personal information; (e) the specific pieces of personal information it has collected about you.

**Response Timing and Format. **

We endeavor to respond to a verifiable request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. All information will be provided in Rich Text or PDF format.

**8. Data Collected on Our Website **

This website itself is primarily directed to businesses that may wish to use our services, but we welcome consumers who want to know more about our services. When you access this website or engage with us by email, we may collect the following information:

**Personally Identifiable Information.**

 When you provide us with information through our website, such as to request or download information, sign up for a newsletter, event or service, or log in to a service or application we provide, we may request and you may provide certain PII to us. We may use your PII for any business purpose, including to administer your account and provide you services, to send communications and administrative emails, to respond to your inquiries, and to send marketing and product update messages. We may also use it to analyze how customers are using our website or services.

**Non-Personally Identifiable Information. **

Whether or not you sign up for an account or provide us with PII, we may collect other information when you visit our website. This may include, the type of browser and operating system you have used to access the website, a timestamp, and other browser or device information; your IP address (which is a number automatically assigned to your computer when you access the Internet and can be used to derive your general geographic location); your clickstream data and any URL referral information (such as any website or social media platform that referred you to our website); what part(s) of our website you visit, click and view, and how long you view it; a unique identifier, which we may associate with a cookie, device identifier or similar technology. We may collect or store the above information by using cookies, web beacons, and similar technologies, and may target or retarget ads to you using those cookies, device identifiers, or similar technologies, when you browse the Internet or use your mobile device. We may combine any information including PII you provide to us.

**Unsubscribing from our email list. **

While we would be sorry to see you go, you can unsubscribe from our corporate emails by accessing the “opt out” or “unsubscribe” (or similar) link we provide in the footer of each email that we send. Keep in mind, however, that even if you delete your account, we may retain your PII to comply with laws, prevent fraud, resolve disputes, troubleshoot problems, assist with any investigations, enforce our agreements and policies, and take other actions permitted by law. Note that even if you unsubscribe or opt-out, we may still send you communications related to your use of and transactions with our services.

**Cookies. **

Cookies are small text files that websites and other online services use to store information about users on users’ own computers. For instance, cookies can be used to store sign-in credentials so that you don’t have to enter them each time you return to a website, or to store other information to make web viewing more customized or efficient upon future visits. Cookies also may store a unique identifier tied to your computer so that web services and content and advertising networks can recognize you across visits to different websites. You can configure your internet browser to warn you each time a cookie is being sent or to refuse all or certain cookies completely. However, this may interfere with the functionality of your web browsing or your ability to fully use many websites.

**Pixels or web beacons. **

Pixels or web beacons may also communicate information from your Internet browser to a web server. Pixels can be embedded in web pages, videos, or emails, and can allow a web server to read certain types of information from your browser, check whether you have viewed a particular web page or email message, and determine, among other things, the time and date on which you viewed the Web beacon, the IP address of your computer, and the URL of the web page from which the Web beacon was viewed.

We sometimes work with third parties to enhance our services (e.g. for purposes of retargeting and tailoring ads or placing browser cookies). These partners may set and access their own cookies, pixel tags and similar technologies on your device, which may have cookies with varying expiration periods. Those partners may, likewise, collect various types of anonymous or de-identified information about your browser, device, or browsing activities through use of these cookies. We also may place cookies when you open an email that we send, in order to help understand how email recipients respond to email campaigns, or to learn more (or help our clients learn more) about those recipients. When you visit our website, we (or a partner we work with) may place cookies to help us target or re-target marketing to you when you visit other websites or web or mobile applications – for instance, to tell you about our services, events and other information about our company. Please note that our business partners may also use cookies and pixels, which we have no control over.

**9. Security and Data Integrity**

Indiemark takes steps to ensure that the data we possess is housed and transmitted securely. These steps may include various methods of physical and electronic security, firewall protections, encryption and hashing of data, and access controls to PII. However, neither we nor any platform can guarantee 100 percent safety from hacks or illegal intrusion, so this Privacy Policy does not guarantee against that possibility.

**10. Questions or Concerns**

If you have questions or concerns about Indiemark’s privacy efforts or data products, please contact us at [*privacy@emailindustries.com*](mailto:privacy@emailindustries.com) or (706) 426-1970

    Indiemark LLC
    801 Broad Street
    Suite 603
    Augusta GA 30901
    USA


 `


export default function PrivacyTermsPopover({open, onClose}) {
    return <Popover title="Terms of Use & Privacy Policy" open={open} onClose={onClose}>
        <ReactMarkdown source={terms}/>
    </Popover>
}
