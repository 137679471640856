import React from 'react';

import Popover from 'components/Popover';


export default function ABoutPopover({open, onClose}) {
    return <Popover title="About us" open={open} onClose={onClose}>
        <p>
            AlfredKnows is brought to you by <a 
            href="https://www.emailindustries.com/">Email Industries</a>.
            For more than two decades, we've been helping email senders and 
            email vendors solve their toughest email deliverability and marketing 
            problems. <a
            href="https://www.emailindustries.com/contact/">What can we do for you today?</a>
        </p>
    </Popover>
}

