import React, {useEffect, useContext} from 'react';
import {useApi} from 'components/Api';
import {useSelector} from 'react-redux';

import {selectUserInfo} from 'ducks/apiDuck';

const IntercomContext = React.createContext();

export function IntercomProvider({appId, children}) {
    useEffect(() => {
        snippet(appId);
    }, [appId]);

    console.log('APPID', appId);
    return <IntercomContext.Provider value={appId} children={children}/>;
}

function snippet(appId) {
    var w=window;
    var ic=w.Intercom;
    if(typeof ic==="function"){
        ic('reattach_activator');
        ic('update',w.intercomSettings);
    } else { 
        var d=document;
        var i=function(){
            i.c(arguments);
        };
        i.q=[];
        i.c=function(args){i.q.push(args);};
        w.Intercom=i;
        var l=function(){
            var s=d.createElement('script');
            s.type='text/javascript';
            s.async=true;
            s.src='https://widget.intercom.io/widget/' + appId;
            var x=d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        };
        if(document.readyState==='complete'){
            l()
            ;
        } else if(w.attachEvent){
            w.attachEvent('onload',l);
        } else {
            w.addEventListener('load',l,false);
        }
    }
}

export function Intercom() {
    const appId = useContext(IntercomContext);
    const userinfo = useSelector(selectUserInfo);
    useEffect(() => {
        if(userinfo) {
            window.Intercom('boot', {
                app_id: appId,
                email: userinfo.email,
                user_id: userinfo.sub
            });
        } else {
            window.Intercom('boot', {
                app_id: appId,
            });
        }
    }, [appId, userinfo]);
    return null;
}



