import React from 'react';

import BasePage from 'components/BasePage';
import UserTable from 'components/UserTable';

export default function AdminPage() {
    return (
        <BasePage title="Admin">
            <UserTable />
        </BasePage>
    )
}
