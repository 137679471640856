import React from 'react';
import fp from 'lodash/fp';
import moment from 'moment';
import {
    Table, TableHead, TableBody, TableRow, TableCell,
    makeStyles,
} from '@material-ui/core';

import {PropTableRows} from './PropTable';
import Currency from './Currency';

const useStyles = makeStyles(theme => ({
    container: {
        /*
        display: 'flex',
        justifyContent: 'center',
        */
    },
    card: {
        padding: 4,
        margin: 16,
        maxWidth: 640,
        alignSelf: 'center',
    }
}));

function OrderSummary({order}) {
    return <PropTableRows title="Order Summary" indent={1}
        object={order} props={{
        'Order ID': fp.get('data.id'),
        'Order Date': fp.pipe([
            fp.get('data.attributes.created'),
            s => moment(s).format('YYYY-MM-DD'),
        ]),
        'Total Lists': fp.get('data.attributes.total_lists'),
        'Total Price': o => <Currency centsUsd={fp.get('data.attributes.total_price', o)}/>
    }}/>
}

function OrderDetail({order}) {
    return (
        <React.Fragment>
            <TableHead>
                <TableRow>
                    <TableCell colspan={3}>Order Details</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colspan={1}/>
                    <TableCell>List Name</TableCell>
                    <TableCell># Emails</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fp.pipe([
                    fp.getOr([], 'data.attributes.line_items'),
                    fp.map(li => (
                        <TableRow>
                            <TableCell colspan={1}/>
                            <TableCell>{li.list_name}</TableCell>
                            <TableCell>{li.num_emails}</TableCell>
                        </TableRow>
                    ))
                ])(order)}
                <TableRow>
                    <TableCell colspan={1}/>
                    <TableCell><strong>Total</strong></TableCell>
                    <TableCell>{fp.get('data.attributes.total_emails', order)}</TableCell>
                </TableRow>
            </TableBody>
        </React.Fragment>
    );
}

function PaymentDetails({order}) {
    const pi = fp.getOr({}, 'data.attributes.payment_info', order);
     
    return <PropTableRows title="Payment details" object={pi} indent={1} props={{
        'Card': pi => `${pi.card_type} XXXX-${pi.card_last4}`,
        'Amount Paid': pi => <Currency centsUsd={fp.get('detail.amount', pi)}/>,
        'Description': fp.get('detail.description'),
    }}/>
}

export default function Order({order}) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Table size="small">
                <OrderSummary order={order}/>
                <TableRow><TableCell colspan={3}/></TableRow>
                <OrderDetail order={order}/>
                <TableRow><TableCell colspan={3}/></TableRow>
                <PaymentDetails order={order}/>
            </Table>
        </div>
    );
}
