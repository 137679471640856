import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    IconButton, Badge, 
    makeStyles
} from '@material-ui/core';

import * as I from '@material-ui/icons';

import {useApi} from 'components/Api';
import {selectCart, cartUpdated} from 'ducks/cartDuck';

import CheckoutDialog from './CheckoutDialog';

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'white',
    },
}));

export default function CartInfo() {
    const api = useApi();
    const dispatch = useDispatch();
    const cart = useSelector(selectCart);
    const [open, setOpen] = useState(false);
    const onCheckout = () => setOpen(true);
    const handleClose = async () => {
        setOpen(false);
        let cart = await api.fetchJson(api.directory.data.links['checkout.cart']);
        dispatch(cartUpdated(cart));
    }
    const classes = useStyles();
    const lists = fp.getOr([], 'data.attributes.line_items', cart);
    const totalPrice = (
        fp.getOr(0, 'data.attributes.total_price', cart) / 100.0
    ).toFixed(2);

    if(!cart) return null;

    return (
        <>
            <CheckoutDialog cart={cart} open={open} handleClose={handleClose}/>
            <IconButton aria-label="cart" title={`Total price: $ ${totalPrice}`}
                onClick={onCheckout}
                disabled={lists.length === 0}
            >
                <Badge badgeContent={lists.length} color="secondary">
                    <I.ShoppingCart className={classes.icon}/>
                </Badge>
            </IconButton>
        </>
    )
}
