import React  from 'react';
import * as R from 'react-router-dom';

import {
    Button, makeStyles
} from '@material-ui/core';

import {url_for} from 'routes';

import BasePage from 'components/BasePage';
import OrdersTable from 'components/OrdersTable';

const useStyles = makeStyles(theme => ({
    content: {
        paddingBottom: 16
    },
    hbox: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        margin: 4,
    }
}));


export default function OrderHistoryPage() {
    const classes = useStyles();

    return (
        <BasePage title="Order History">
            <div className={classes.content}>
                <div className={classes.hbox}>
                    <Button component={R.Link} to={url_for('dashboard')} 
                        className={classes.button} variant="contained" color="primary">
                        View your lists
                    </Button>
                </div>
                <OrdersTable />
            </div>
        </BasePage>
    )
}

