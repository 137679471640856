import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Drawer, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {useApi} from 'components/Api';
import {url_for} from '../routes';
import {selectUserInfo} from 'ducks/apiDuck';

function AdminItems() {
    return (
        <>
            <ListItem button component={R.Link} to={url_for('admin')}>
                <ListItemIcon><I.SupervisorAccount /></ListItemIcon>
                <ListItemText primary="Admin Panel"/>
            </ListItem>
        </>
    )
}

function AuthorizedItems() {
    return (
        <React.Fragment>
            <ListItem button component={R.Link} to={url_for('dashboard')}>
                <ListItemIcon><I.List/></ListItemIcon>
                <ListItemText primary="Your lists"/>
            </ListItem>
            <ListItem button component={R.Link} to={url_for('orders')}>
                <ListItemIcon><I.History/></ListItemIcon>
                <ListItemText primary="Order history"/>
            </ListItem>
            <ListItem button component={R.Link} to={url_for('userSettings')}>
                <ListItemIcon><I.Person/></ListItemIcon>
                <ListItemText primary="User settings"/>
            </ListItem>
        </React.Fragment>
    )
}

export default function NavDrawer({open, onClose}) {
    const api = useApi();
    const userinfo = useSelector(selectUserInfo);
    const permissions = fp.getOr([], 'permissions', userinfo);
    const isAdmin = fp.find(p => p === 'admin', permissions);
    console.log('UserInfo', userinfo, permissions, isAdmin);

    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                {api.isAuthorized() && <AuthorizedItems />}
                {isAdmin && <AdminItems/>}
            </List>
        </Drawer>
    )
}
