import React from 'react';
import * as R from 'react-router-dom';

import routes, {history} from '../routes';

import {ApiRoute} from 'components/Api';

import LoginPage from 'fl-template/pages/login';
import CallbackPage from '../pages/CallbackPage';
import DashboardPage from '../pages/DashboardPage';
import OrderHistoryPage from '../pages/OrderHistoryPage';
import OrderPage from '../pages/OrderPage';
import UserSettingsPage from 'pages/UserSettingsPage';
import VerifyEmailPage from 'pages/VerifyEmailPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import ConfirmAccountPage from 'pages/ConfirmAccountPage';

import AdminPage from 'pages/AdminPage';

export default function Router() {
    return (
        <R.Router history={history}>
            <R.Switch>
                <ApiRoute authorized path={routes.admin.path} component={AdminPage}/>
                <ApiRoute exact path={routes.home.path} component={LoginPage}/>
                <ApiRoute exact path={routes.callback.path} component={CallbackPage}/>
                <ApiRoute authorized exact path={routes.dashboard.path} component={DashboardPage}/>
                <ApiRoute authorized exact path={routes.orders.path} component={OrderHistoryPage}/>
                <ApiRoute authorized exact path={routes.order.path} component={OrderPage}/>
                <ApiRoute authorized exact path={routes.userSettings.path} component={UserSettingsPage}/>
                <ApiRoute exact path={routes.verifyEmail.path} component={VerifyEmailPage}/>
                <ApiRoute exact path={routes.resetPassword.path} component={ResetPasswordPage}/>
                <ApiRoute exact path={routes.confirmAccount.path} component={ConfirmAccountPage}/>
            </R.Switch>
        </R.Router>
    )
}
