import React, {useRef} from 'react';
import {useSnackbar} from 'notistack';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';
import {Chip, TextField, makeStyles} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

import {useApi} from 'components/Api';

const useStyles = makeStyles(theme => ({
    chip: {
        margin: theme.spacing(1)
    }
}));


const availablePermissions = ['admin', 'esp', 'append', 'deliverability', 'risk'];

const PermissionView = ({rowData}) => {
    const classes = useStyles();
    return (
        <span>
            {fp.pipe([
                fp.get('data.attributes.permissions'),
                fp.map(p => <Chip className={classes.chip} key={p} label={p} size='small'/>)
            ])(rowData)}
        </span>
    );
}

const PermissionEdit = ({value, onChange}) => {
    const handleChange = (ev, newValue) => {
        onChange(newValue);
    };
    return (
        <Autocomplete multiple value={value} onChange={handleChange} 
            options={availablePermissions}
            renderInput={params => <TextField {...params}/>}
        />
    );
}

const columns = [
    {
        title: "Email", field: 'data.attributes.primary_email.address',
        editable: 'never',

    },
    {
        title: 'Permissions', 
        field: 'data.attributes.permissions',
        render: (rowData, ...args) => {
            return <PermissionView rowData={rowData}/>;
        },
        editComponent: PermissionEdit
    },

];



export default function UserTable() {
    const api = useApi();
    const tableRef = useRef();
    const {enqueueSnackbar} = useSnackbar();

    const fetchUsers = async tableFetchOptions => {
        let rv = await api.fetchMaterialTable(
            api.directory.data.links['auth.users'], {
                ...tableFetchOptions,
            }
        );
        return rv;
    };

    const refresh = () => {
        if(tableRef) {
            tableRef.current.onQueryChange()
        }
    }

    const onRowUpdate = async (newData, oldData) => {
        try {
            await api.fetchJson(newData.data.links.self, {
                method: 'PATCH',
                json: {data: newData.data}
            });
        } catch(e) {
            enqueueSnackbar(`Error: ${e.response.statusText}`, {variant: 'error'})
        }
        refresh();
    }


    return (
        <MaterialTable title="Users" tableRef={tableRef}
            columns={columns}
            data={fetchUsers}
            editable={{ onRowUpdate }}
            actions={[
                {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: refresh},
            ]}

        />
    );

}
