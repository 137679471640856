import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {
    Button, Tooltip, Badge,
    TextField, IconButton,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {useSnackbar} from 'notistack';

import BasePage from 'components/BasePage';

import {useApi} from 'components/Api';
import {selectUserInfo} from 'ducks/apiDuck';

const useStyles = makeStyles(theme => ({
    contentArea: {
        padding: theme.spacing(4),
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    success: {
        color: 'green',
    },
}));


function ReverifyLink({user}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const requestVerification = async () => {
        await api.fetchJson(user.data.links['add-email'], {
            method: 'POST',
            json: {data: {'type': 'Email', 'id': user.data.attributes.primary_email.address}}
        });
        enqueueSnackbar(
            'Please click on the link in the confirmation email to confirm your address',
            {'variant': 'success'}
        );
    }

    return <>
        &nbsp; &nbsp;
        <Button onClick={requestVerification}>Re-send confirmation email</Button>
    </>
}


function AddressWidget({user}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    console.log('AdWid', user);
    const email = fp.get('data.attributes.primary_email', user);
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState("");
    const onEdit = () => {
        setValue(email.address);
        setEditing(true);
    }
    const onSave = async () => {
        console.log('Save email', value);
        await api.fetchJson(user.data.links['add-email'], {
            method: 'POST',
            json: {data: {'type': 'Email', 'id': value}}
        });
        enqueueSnackbar(
            'Please click on the link in the confirmation email to confirm your address change',
            {'variant': 'success'}
        )
    }
    const onCancel = () => {
        setValue(email.address);
        setEditing(false);
    }
    if(editing) {
        return (
            <>
                <TextField value={value} onChange={ev => setValue(ev.target.value)}/>
                <IconButton onClick={onSave}><I.Check/></IconButton>
                <IconButton onClick={onCancel}><I.Cancel/></IconButton>
            </>
        )
    } else {
        return (
            <>{email.address} <IconButton onClick={onEdit}><I.Edit/></IconButton></>
        )
    }
}


function EmailWidget({user}) {
    const email = fp.get('data.attributes.primary_email', user);
    const verified = fp.get('verified', email);

    const tooltip = verified ? "Confirmed email" : "Unconfirmed email";
    const icon = verified ? null : '!';

    if(!user) return null;
    return (
        <>
            <Tooltip title={tooltip}>
                <Badge badgeContent={icon} color="secondary">
                    Email: &nbsp;
                </Badge>
            </Tooltip> 
            &nbsp;&nbsp;&nbsp;&nbsp; <AddressWidget user={user}/>
            {verified ? null : <ReverifyLink user={user}/>}
        </>
    )
}

function PasswordWidget({user}) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();
    const [changing, setChanging] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const changePassword = () => {
        setOldPassword('');
        setNewPassword('');
        setNewPassword2('');
        setChanging(true);
    }
    const onSave = async () => {
        try {
            await api.fetchJson(user.data.links['set-password'], {
                method: 'POST',
                json: {data: {type: 'PasswordChange', attributes: {
                    old_password: oldPassword, 
                    password: newPassword
                }}}
            });
            setChanging(false);
        } catch(error) {
            try {
                let data = await error.response.json();
                console.log(error.response);
                let message = fp.getOr(error.response.statusText, 'message', data);
                enqueueSnackbar(`Error: ${message}`, {variant: 'error'})
            } catch(e) {
                enqueueSnackbar('Unknown error', {variant: 'error'})
            }
        }
    }
    const onCancel = () => {
        setChanging(false);
    }
    if(changing) {
        return (
            <>
                <TextField type="password" label="Current Password" 
                    value={oldPassword} onChange={ev => setOldPassword(ev.target.value)} />
                &nbsp;
                <TextField type="password" label="New Password" 
                    value={newPassword} onChange={ev => setNewPassword(ev.target.value)} />
                &nbsp;
                <TextField type="password" label="New Password (again)" 
                    value={newPassword2} onChange={ev => setNewPassword2(ev.target.value)} />
                &nbsp;
                <IconButton onClick={onSave} disabled={
                    !newPassword.length || newPassword !== newPassword2
                }><I.Check/></IconButton>
                <IconButton onClick={onCancel}><I.Cancel/></IconButton>
            </>
        );
    } else {
        return (
            <Button onClick={changePassword}>Change password</Button>
        )
    }
}

function APIKeyWidget({user, setUser}) {
    const api = useApi();
    const onReset = async () => {
        let resp = await api.fetchJson(
            user.data.links['regenerate-api-key'], 
            { method: 'POST' }
        );
        console.log('resp was', resp);
        setUser(resp);
    }
    const rawKey = fp.get('data.attributes.api_key', user);

    return (
        <>
            <div style={{display: 'flex'}}>
               <TextField 
                   label="API Key" 
                   InputLabelProps={{ shrink: true }}
                   InputProps={{ readOnly: true }}
                   value={`alfred.api-${rawKey}`}
                   style={{flex: 1}}
                />
               <Tooltip title="Regenerate your API Key">
                   <IconButton onClick={onReset}><I.Refresh/></IconButton>
                </Tooltip>
            </div>
        </>
    )
}

export default function UserSettingsPage() {
    const api = useApi();
    const classes = useStyles();
    const userinfo = useSelector(selectUserInfo);
    const [user, setUser] = useState();

    useEffect(
        () => {
            const f = async () => {
                let user = await api.fetchJson(userinfo.profile);
                setUser(user);
            }
            f();
        }, 
        [api, userinfo, setUser]
    );
    /*
    const user = useAsyncMemo(async () => {
        if(userinfo) {
            return await api.fetchJson(userinfo.profile);
        }
    }, [api, userinfo]);
    */

    return (
        <BasePage title="User Settings">
            <div className={classes.contentArea}>
                <div><EmailWidget user={user}/></div>
                <div><PasswordWidget user={user}/></div>
                <div><APIKeyWidget user={user} setUser={setUser}/></div>
            </div>


        </BasePage>
    )
}

