import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {
    Button, Icon, 
    Table, TableContainer, TableHead, TableRow, TableCell, TableBody,
    Paper, Dialog, DialogTitle, DialogContent,
    makeStyles,
} from '@material-ui/core';

import {useAsyncMemo} from 'lib/util';
import {useApi} from 'components/Api';

const mapWithKey = fp.map.convert({cap: false});
const useStyles = makeStyles(theme => ({
    tableContainer: {
    },
    padded: {
        padding: 16,
    },
    error: {
        color: 'red',
    },
    success: {
        color: 'green',
    },
}));

const DataRow = ({value}) => (
    <TableRow>
        {mapWithKey(
            (cell, c) => <TableCell key={c} children={cell}/>, 
            value
        )}
    </TableRow>
)

const SelectButton = ({column, emailColumn, setEmailColumn}) => {
    const onClick = ev => setEmailColumn(column);
    return (
        column === emailColumn 
        ? <Button variant="contained" color="primary">Selected</Button>
        : <Button onClick={onClick}>Select</Button>
    );
}

function MappingDetail({list, updateList}) {
    const classes = useStyles();
    const api = useApi();
    const listData = useAsyncMemo(async () => {
        let head = await api.fetchJson(list.relationships.head.links.related);
        return head.data.attributes.data;
    });
    const setEmailColumn = useCallback(c => {
        const data = fp.set('attributes.email_column', c, list)
        updateList(data);
    }, [list, updateList])
    if(!listData) return null;

    const nRow = listData.length;
    const nCol = nRow > 0 ? listData[0].length : 0;
    const numEmails = fp.get('attributes.num_emails', list);
    const emailColumn = fp.get('attributes.email_column', list);


    return (
        <div className={classes.padded}>
            <p>Please select which column of your file contains email addresses.</p>
            {numEmails === 0 && (
                <p>
                    <Icon className={classes.error}>error</Icon> We couldn't find any emails in the column you 
                    selected. Please make sure you are uploading a CSV file containing 
                    email addresses in one column, and that you have selected the correct 
                    column.
                </p>
            )}

            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {fp.map(
                                c => (
                                    <TableCell key={c}>
                                        <SelectButton column={c} 
                                            emailColumn={emailColumn} setEmailColumn={setEmailColumn}
                                        />
                                    </TableCell>
                                ), 
                                fp.range(0, nCol)
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mapWithKey(
                            (row, r) => <DataRow key={r} value={row}/>,
                            listData
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}


function PropTable({object, props}) {
    return (
        <Table size="small">
            <TableBody>
                {fp.map(prop => (
                    <TableRow key={prop}>
                        <TableCell>{prop}</TableCell>
                        <TableCell>{fp.get(prop, object)}</TableCell>
                    </TableRow>
                ), props)}
            </TableBody>
        </Table>
    )
}

function ValidationDetail({list}) {
    const classes = useStyles();
    return (
        <Paper className={classes.detail}>
            <PropTable 
                object={fp.get('attributes.verification_detail', list)}
                props={[
                    'created', 'started', 'completed', 'duplicates', 
                    'progress.completed', 'progress.deliverable', 'progress.total',
                ]}
            />
        </Paper>
    )
}



export default function ListDetailDialog({list, setList, updateList, inCart}) {
    const name = fp.get('attributes.name', list);
    const status = fp.get('attributes.status', list);
    const showValidationDetail = status === 'completed';
    const showMappingDetail = status === 'new' && !inCart(list);
    return (
        <Dialog open={!!list} onClose={() => setList(null)}>
            <DialogTitle>{name} details</DialogTitle>
            <DialogContent>
                Status: {status}<br/>
                {showValidationDetail && <ValidationDetail list={list}/>}
                {showMappingDetail && <MappingDetail list={list} updateList={updateList}/>}
            </DialogContent>
        </Dialog>
    )
}
