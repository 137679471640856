import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Button,
    Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';

import {useSnackbar} from 'notistack';

import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';

import {useApi} from 'components/Api';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

export default function CheckoutDialog({open, handleClose, cart}) {
    const api = useApi();
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const {enqueueSnackbar} = useSnackbar();
    if(!cart) return null;

    const handleChange = ev => {
        if(ev.error) {
            setError(ev.error.message);
        } else {
            setError(null);
        }
    }

    const handleSubmit = async ev => {
        ev.preventDefault();
        setDisabled(true);
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card)
        if (result.error) {
            // Inform the user if there was an error.
            setError(result.error.message);
            setDisabled(false);
            return;
        } 
        console.log('Made a token', result.token);
        try {
            await api.fetchJson(api.directory.data.links['checkout.orders'], {
                method: 'POST',
                json: {
                    data: {
                        type: 'Order',
                        attributes: {
                            cart_id: cart.data.id,
                            token: result.token.id
                        }
                    }
                }
            });
            enqueueSnackbar('Checkout succeeded', {variant: 'success'})
        } catch(e) {
            console.error('Error checking out', e);
            enqueueSnackbar('Checkout error, please contact support', {variant: 'error'})
        }
        handleClose();
        setDisabled(false);
    }; 
    const num_emails = fp.get('data.attributes.total_emails', cart);
    const total_price = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD'
    }).format(fp.getOr(0, 'data.attributes.total_price', cart) / 100);

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Checkout</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill in your payment details to purchase verification for your lists.
                    </DialogContentText>
                    <DialogContentText>
                        Total price for {num_emails} email(s) is {total_price}
                    </DialogContentText>
                    {error}
                    <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChange}
                    />
                    <DialogActions>
                        <Button disabled={disabled} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button disabled={disabled} type="submit" color="primary">
                            Checkout
                        </Button>
                    </DialogActions>    
                </DialogContent>
            </form>
        </Dialog>
    );
}
