import React from 'react';
import fp from 'lodash/fp';
import {
    Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';


import Popover from 'components/Popover';


import {useApi} from 'components/Api';
import {useAsyncMemo} from 'lib/util';


function PriceRow({tier}) {
    return (
        <TableRow>
            {tier.max 
                ?  <TableCell>{tier.min} to {tier.max}</TableCell>
                : <TableCell>{tier.min} and up</TableCell>
            }
            <TableCell><span dangerouslySetInnerHTML={{__html: tier.copy}}/></TableCell>
        </TableRow>

    )
}

export default function PricingPopover({open, onClose}) {
    const api = useApi();
    const priceData = useAsyncMemo(async () => {
        return await api.fetchJson(api.directory.data.links['pricing.pricing']);
    });
    const tiers = fp.get('data.attributes.tiers', priceData);
    return (
        <Popover open={open} onClose={onClose} title="Email Verification Pricing">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Number of Emails</TableCell>
                        <TableCell>Price per Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fp.map(tier => <PriceRow key={tier.min} tier={tier}/>, tiers)}
                </TableBody>
            </Table>
        </Popover>
    )
}
