import React, {useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import moment from 'moment';

import {
    Button, Icon, makeStyles
} from '@material-ui/core';

import {useApi} from 'components/Api';
import {selectLists} from 'ducks/listDuck';

import BasePage from 'components/BasePage';
import DashCartTable from 'components/DashCartTable';

const useStyles = makeStyles(theme => ({
    header: {
        'text-align': 'center',
        padding: 4,
    },
    vbox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    button: {
        margin: 4,
    },
    input: {
        display: 'none'
    },
    cartArea: {
        display: 'flex'
    },
}));


export default function DashboardPage() {
    const classes = useStyles();
    const inputRef = useRef();
    const tableRef = useRef();
    const api = useApi();
    const lists = useSelector(selectLists);

    const onInputChange = useCallback(async ev => {
        const files = ev.target.files;
        const url = api.directory.data.links['list.collection'];
        fp.map(async f => {
            const body = new FormData();
            body.append('date', moment().format());
            body.append('data', f)
            const resp = await api.fetch(url, {
                method: 'POST', 
                body
            })
            console.log('upload result', resp);
            tableRef.current.onQueryChange();
            inputRef.current.value = "";
        }, files);
    }, [api])

    const onUpload = () => inputRef.current && inputRef.current.click();

    return (
        <BasePage title="Dashboard">
            <input type="file" multiple className={classes.input} ref={inputRef}
                onChange={onInputChange}
            />
            {!fp.isEmpty(lists) && lists.length === 0 && (
                <header className={classes.header}>
                        <div className={classes.vbox}>
                            <h3>You don't have any lists in your account</h3>
                            <Button variant="contained" color="primary" 
                                startIcon={<Icon>cloud_upload</Icon>}
                                onClick={onUpload}
                            >
                                Upload a list
                            </Button>
                        </div>
                </header>
            )}
            <DashCartTable tableRef={tableRef} onUpload={onUpload}/>
        </BasePage>
    )
}
