import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { ThemeProvider } from "@material-ui/styles";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "fl-template/context/LayoutContext";
import { UserProvider } from "fl-template/context/UserContext";
import { ManagementProvider } from "fl-template/context/ManagementContext";
import { CssBaseline } from "@material-ui/core";
import config from "fl-template/config";
import themes from 'fl-template/themes';

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common["Content-Type"] = "application/json";
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

console.log('themes', themes);

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
        <ThemeProvider theme={themes.success}>
            <ManagementProvider>
            <CssBaseline />
            <App />
            </ManagementProvider>
        </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
