import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Link, Button, Badge, Tooltip, makeStyles} from '@material-ui/core';

import {url_for} from 'routes';
import {useApi} from 'components/Api';
import {selectUserInfo} from 'ducks/apiDuck';

const useStyles = makeStyles(theme => ({
    link: {
        color: 'white'
    }
}));


function Email() {
    const classes = useStyles();
    const userinfo = useSelector(selectUserInfo);
    const email = fp.get('email', userinfo);
    const isVerified = fp.getOr(false, 'email_verified', userinfo);
    if(!userinfo) {
        return null;
    }
    if(isVerified) {
        return <Link className={classes.link} component={R.Link} to={url_for('userSettings')}>{email}</Link>
    } else {
        return (
            <Tooltip title="Verify your email address">
                <Badge color="secondary" badgeContent="!">
                    <Link className={classes.link} component={R.Link} to={url_for('userSettings')}>{email}</Link>
                </Badge>
            </Tooltip>
        )
    }
}


export default function AuthInfo() {
    const api = useApi();
    const userinfo = useSelector(selectUserInfo);
    if(!userinfo) return null;

    return (
        <div>
            Welcome, <Email /> <Button color="inherit" onClick={api.logout}>Sign out</Button>
        </div>
    );
}
