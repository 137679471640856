import React  from 'react';
import {
    Dialog, DialogTitle, DialogContent,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    title: {
        marginRight: 30,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));


export default function Popover({title, children, open, onClose}) {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle >
                <span className={classes.title}>{title}</span>
                <IconButton onClick={onClose} className={classes.closeButton}>
                    <I.Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    )
}
