import {defineAction} from 'redux-define';
import {createAction, handleActions} from 'redux-actions';

export const NAME = 'cart';

// actions
export const UPDATE = defineAction('UPDATE', NAME);

// action creators
export const cartUpdated = createAction(UPDATE.ACTION);

// selectors
export const selectCart = state => state[NAME];

const initialState = null;

export default {
    [NAME]: handleActions({
        [UPDATE.ACTION]: (state, {payload}) => payload
    }, initialState)
}
