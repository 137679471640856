import React, {useEffect, useCallback, useRef, useState} from 'react';
import * as R from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';
import * as I from '@material-ui/icons';
import {Button} from '@material-ui/core';

import {url_for} from '../routes';
import {useApi} from 'components/Api';
import {selectCart, cartUpdated} from 'ducks/cartDuck';
import {listsRefreshed} from 'ducks/listDuck';

import ValidationColumn from './ValidationColumn';
import ListDetailDialog from './ListDetailDialog';


function OrderLink({order}) {
    if(!order || !order.data) return null;
    return <R.Link to={url_for('order', {order_id: order.data.id})}
        className="green-link"
    >Receipt</R.Link>
}

export default function DashCartTable({
    tableRef, onUpload
}) {
    const api = useApi();
    const _tableRef = useRef();
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(5);
    const [detailList, setDetailList] = useState(null);

    const cart = useSelector(selectCart);

    if(!tableRef) {
        tableRef = _tableRef;
    }

    const inCart = lst => fp.find(
        li => li.list_id === lst.id,
        fp.get('data.attributes.line_items', cart)
    )

    const columns = [
        {title: 'LIST NAME', field: 'data.attributes.name'},
        {title: 'EMAILS', field: 'data.attributes.num_emails'},
        {title: 'DATE', field: 'data.attributes.date', type: 'date'},
        {title: 'PRICE', field: 'data.attributes.price' },
        {
            title: 'STATUS', 
            render: rowData => <ValidationColumn list={rowData.data} inCart={inCart} 
                onChanged={tableRef.current.onQueryChange} setDetailList={setDetailList}
            />,
            sorting:false
        },
        {
            title: '', field: 'data.relationships.order.data.id', 
            render: rowData => <OrderLink order={fp.get('data.relationships.order', rowData)}/>
        },
    ];

    const fetchLists = async tableFetchOptions => {
        let rv = await api.fetchMaterialTable(
            api.directory.data.links['list.collection'], {
                ...tableFetchOptions,
                include: ['order'],
                onFetched: json => dispatch(listsRefreshed(json))
            }
        );
        console.log('Table fetch', rv);
        return rv;
    };

    const fetchCart = useCallback(async () => {
        let cart = await api.fetchJson(api.directory.data.links['checkout.cart']);
        dispatch(cartUpdated(cart));
    }, [api, dispatch]);

    const refresh = () => {
        fetchCart();
    };

    const updateList = async data => {
        await api.fetchJson(data.links.self, {
            method: 'PATCH',
            json: {data}
        });
        setDetailList(null);
        refresh();
    };

    const onRowDelete = async rowData => {
        console.log('delete', rowData);
        await api.fetch(rowData.data.links.self, {method: 'DELETE'});
        fetchCart()
    }

    useEffect(() => {
        if(tableRef.current) {
            fetchCart();
        }
    }, [fetchCart, tableRef])

    useEffect(() => {
        if(tableRef.current) {
            tableRef.current.onQueryChange()
        }
    }, [cart, tableRef]);

    return (
        <React.Fragment>
            <ListDetailDialog 
                list={detailList} 
                updateList={updateList} 
                setList={setDetailList} 
                inCart={inCart}
            />
            <MaterialTable
                title="My Lists"
                columns={columns}
                data={fetchLists}
                tableRef={tableRef}
                editable={{ onRowDelete }}
                localization={{
                    header: {
                        actions: 'ACTIONS',
                    },
                    body: {
                        emptyDataSourceMessage: 'You have not uploaded any lists.'
                    }
                }}
                /*
                detailPanel={[
                    rowData => mappingDetail(rowData, updateList, inCart), 
                    rowData => validationDetail(rowData),
                ]}
                 */
                options={{ 
                    search: false,
                    pageSize: pageSize,   // workaround bug #1941
                    actionsColumnIndex: 1,
                }}
                icons={{
                    Delete: I.Delete,
                }}
                onChangeRowsPerPage={setPageSize}  // workaround #1941
                actions={[
                    {
                        icon: () => <Button variant="contained" color="primary">UPLOAD NEW LIST</Button>,//'add', 
                        tooltip: 'Add a list', 
                        isFreeAction: true, 
                        onClick: onUpload
                    },
                    {
                        icon: () => <Button variant="contained">REFRESH</Button>, // 'refresh', 
                        tooltip: 'Refresh', 
                        isFreeAction: true, 
                        onClick: refresh
                    },
                    {icon: 'info', tooltip: 'List info', onClick: (ev, rowData) => {
                        setDetailList(rowData.data);
                    }},
                ]}
            />
        </React.Fragment>
    )

}
