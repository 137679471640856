import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import {TextField, Button, makeStyles} from '@material-ui/core';

import {useApi} from 'components/Api';
import {useQuery, history, url_for} from 'routes';
import BasePage from 'components/BasePage';

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(4),
    },
    contentArea: {
        padding: theme.spacing(4),
    }
}));

function ConfirmAccountForm({token}) {
    const api = useApi();
    const [password, setPassword] = useState('');
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    const handleSubmit = async e => {
        e.preventDefault();

        try {
            let resp = await api.fetchJson(api.directory.data.links['auth.password-reset'], {
                method: 'POST',
                json: {data: {type: 'PasswordReset', attributes: { token, password }}}
            });
            enqueueSnackbar('Password changed', {variant: 'success'})
            await api.login({
                grant_type: 'refresh_token', provider_id: resp.provider_id, refresh_token: resp.refresh_token
            });
            history.push(url_for('dashboard'))
        } catch(e) {
            enqueueSnackbar('Error', {variant: 'error'})
        }
    }
    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextField type="password" label="New password" fullWidth
                value={password} onChange={e => setPassword(e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary">Set Password</Button>
        </form>
    )
}

export default function ConfirmAccountPage() {
    const q = useQuery();
    const token = q.get('token');
    const classes = useStyles();
    return (
        <BasePage title="Pasword reset">
            <div className={classes.contentArea}>
                <h4>Complete your account</h4>
                <ConfirmAccountForm token={token}/>
            </div>
        </BasePage>
    )
}

