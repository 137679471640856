import React, {
    createContext, 
    useState, useMemo, useEffect, useContext
} from 'react';
import {Route} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {history, url_for} from 'routes';
import {useStorage} from 'lib/util';
import {Api} from 'lib/api';
import {selectDirectory, selectProviders, selectToken} from 'ducks/apiDuck'

export const ApiContext = createContext()

export function ApiRoute({authorized=false, ...props}) {
    const api = useContext(ApiContext);
    const [init, setInit] = useState(false);
    const [apiIsReady, setApiIsReady] = useState(false);

    useEffect(() => {
        async function main() {
            await api.bootstrap();
            if(authorized && !api.isAuthorized()) {
                history.push(url_for('home'))
            }
            else {
                setApiIsReady(true);
            }
        }
        if(!init) {
            setInit(true);
            main();
        }
    }, [api, init, setInit, setApiIsReady, authorized]);

    return (apiIsReady ? <Route {...props}/> : null);
}

export const useApi = () => useContext(ApiContext);

export default function ApiProvider({apiRoot, children}) {
    const [providerId, setProviderId] = useStorage(sessionStorage, 'alfred.providerId', null);
    const [refreshToken, setRefreshToken] = useStorage(sessionStorage, 'alfred.refreshToken', {});
    const dispatch = useDispatch();
    const directory = useSelector(selectDirectory);
    const providers = useSelector(selectProviders);
    const accessToken = useSelector(selectToken);
    const api = useMemo(
        () => new Api({
            apiRoot, providerId, setProviderId, refreshToken, setRefreshToken,
            directory, providers, accessToken, dispatch
        }), 
        [
            apiRoot, refreshToken, setRefreshToken,
            directory, providers, accessToken, dispatch,
            providerId, setProviderId,
        ]
    );
    return <ApiContext.Provider value={api} children={children}/>;
}

