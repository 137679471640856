import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {reverse} from 'named-urls';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();

const routeList = [
    {name: 'home', path: '/'},
    {name: 'admin', path: '/admin'},
    {name: 'login', path: '/login'},
    {name: 'callback', path: '/callback'},
    {name: 'dashboard', path: '/dashboard'},
    {name: 'cart', path: '/cart'},
    {name: 'orders', path: '/order'},
    {name: 'order', path: '/order/:order_id'},
    {name: 'userSettings', path: '/settings'},
    {name: 'verifyEmail', path: '/verify-email'},
    {name: 'resetPassword', path: '/reset-password'},
    {name: 'confirmAccount', path: '/confirm-account'},
];

export const routes = fp.transform(
    (acc, r) => { acc[r.name] = r; },
    {},
    routeList
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const routeByType = fp.transform(routeList, (acc, r) => {
    if(r.type) {
        acc[r.type] = r;
    }
}, {});

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params)
    } catch(e) {
        console.error('Error looking up route', route, params)
        throw(e);
    }
}

export default routes;
