import React, {useState} from 'react';

import {Link, Paper, makeStyles} from '@material-ui/core';

import AppBar from './AppBar';
import NavDrawer from './NavDrawer';
import PrivacyTermsPopover from './PrivacyTermsPopover';
import {Intercom} from 'components/Intercom';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0
        },
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': {
            ...theme.typography.h4,
            margin: 0
        },
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
        '& p': theme.typography.body1,
        height: "100vh",
        '& a.green-link': {
            color: '#459e76'
        },
    },
    footer: {
        position: 'fixed',
        bottom: 0,
        width: "100%",
        textAlign: 'center',
        backgroundColor: '#F6F7FF',
        height: 56,
    },
    contentContainer: {
        // backgroundImage: "url(/img/background.png)",
        padding: 16,
        marginBottom: 56,
    },
    contentArea: {
        // padding: 16,
        maxWidth: 1280,
        marginLeft: 'auto',
        marginRight: 'auto',
    }
}));

export default function BasePage({title, children}) {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);

    return (
        <div className={classes.root}>
            <Intercom/>
            <PrivacyTermsPopover open={termsOpen} onClose={() => setTermsOpen(false)}/>
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)}/>
            <AppBar title={title} onToggleDrawer={() => setDrawerOpen(!drawerOpen)}/>
            <div className={classes.contentContainer}>
                <Paper className={classes.contentArea}>{children}</Paper>
            </div>
            <footer className={classes.footer}>
                <p>
                    &copy; 2020 All Rights Reserved. AlfredKnows &reg; is 
                    a registered trademark of Email Industries. 
                    <Link onClick={() => setTermsOpen(true)}>Privacy & Terms</Link>
                </p>
            </footer>
        </div>
    )
}
