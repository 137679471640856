import fp from 'lodash/fp';
import moment from 'moment';
import {defineAction} from 'redux-define';
import {createAction, handleActions} from 'redux-actions';
import {createSelector} from 'reselect';

import {url_for} from '../routes';

export const NAME = 'auth';

// actions
export const LOGIN = defineAction('LOGIN', NAME);
export const LOGOUT = defineAction('LOGOUT', NAME);
export const USERINFO = defineAction('USERINFO', NAME);
export const DIRECTORY = defineAction('DIRECTORY', NAME);

// action creators
export const loggedIn = createAction(LOGIN.ACTION);
export const loggedOut = createAction(LOGOUT.ACTION);
export const fetchedUserInfo = createAction(USERINFO.ACTION);
export const fetchedDirectory = createAction(DIRECTORY.ACTION);

// selectors
export const selectDirectory = state => state[NAME].directory;
export const selectProviders = state => state[NAME].providers;
export const selectToken = state => state[NAME].accessToken;
export const selectUserInfo = state => state[NAME].userInfo;

export const selectProviderLinks = provider_id => createSelector(
    selectProviders, providers => {
        if(!providers) return {}
        const provider = fp.find(p => p.id === provider_id, providers.data);
        let links = fp.pipe([
            fp.toPairs,
            fp.map(
                ([link_id, link]) => {
                    let url = new URL(link);
                    url.searchParams.set('redirect_uri', new URL(
                        url_for('callback'), window.location
                    ).href);
                    url.searchParams.set('response_type', 'code');
                    url.searchParams.set('state', 'Georgia');
                    url.searchParams.set('next', url_for('dashboard'));
                    return [link_id, url.href];
                }
            ),
            fp.fromPairs,
        ])(provider.links);
        return links;
    }
)

export const selectCognitoLinks = selectProviderLinks('cognito');

const initialState = {
    accessToken: null,
    providerId: null,
    userInfo: null,
    directory: null,
    providers: null,
}

export default {
    [NAME]: handleActions({
        [DIRECTORY.ACTION]: (state, {payload}) => fp.set('directory', payload, state),
        [USERINFO.ACTION]: (state, {payload}) => fp.set('userInfo', payload, state),
        [LOGIN.ACTION]: (state, {payload}) => {
            return fp.set('accessToken', {
                data: payload.access_token,
                expires: moment().add(payload.expires_in, 'seconds').toISOString(),
            }, state);
        },
        [LOGOUT.ACTION]: (state) => ({
            ...initialState,
            directory: state.directory,
            providers: state.providers
        }),
    }, initialState)
}
