import React from 'react';
import fp from 'lodash/fp';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';

export function PropTableRows({object, props, title, indent=0}) {
    return (
        <React.Fragment>
            {title && (
                <TableHead>
                    <TableRow>
                        <TableCell colspan={indent + 2}>{title}</TableCell>
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {fp.pipe([
                    fp.toPairs,
                    fp.map(
                        ([label, getter]) => (
                            <TableRow key={label}>
                                {indent && <TableCell colSpan={indent}/>}
                                <TableCell>{label}</TableCell>
                                <TableCell>{getter(object)}</TableCell>
                            </TableRow>
                        )
                    )
                ])(props)}
            </TableBody>
        </React.Fragment>
    );
}

export default function PropTable({object, props, title, indent=0, ...other}) {
    return (
        <Table {...other}>
            <PropTableRows object={object} props={props} title={title} indent={indent}/>
        </Table>
    );
}

