import React, {useEffect, useCallback, useRef} from 'react';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';

import {url_for} from 'routes';
import {useApi} from 'components/Api'
import Currency from 'components/Currency';


export default function OrderTable() {
    const api = useApi();
    const tableRef = useRef();

    const columns = [
        {
            title: 'Order ID', field: 'data.id', 
            render: rowData => (
                <R.Link to={url_for('order', {order_id: rowData.data.id})}>
                    {fp.get('data.id', rowData)}
                </R.Link>
            )
        },
        {title: 'Order Date', field: 'data.attributes.created', type: 'datetime'},
        {title: 'Total Lists', field: 'data.attributes.total_lists'},
        {title: 'Total Emails', field: 'data.attributes.total_emails'},
        {
            title: 'Total Price', field: 'data.attributes.total_price',
            render: rowData => <Currency centsUsd={fp.get('data.attributes.total_price', rowData)}/>
        }
    ];

    const fetchData = useCallback(async tableFetchOptions => {
        let rv = await api.fetchMaterialTable(
            api.directory.data.links['checkout.orders'],
            tableFetchOptions
        );
        return rv;
    }, [api]);

    const refresh = useCallback(
        () => tableRef.current.onQueryChange(),
        [tableRef]
    );

    useEffect(() => {
        tableRef.current.onQueryChange();
    }, [tableRef, api])

    return (
        <React.Fragment>
            <MaterialTable
                title="Order History"
                columns={columns}
                data={fetchData}
                tableRef={tableRef}
                detailPanel={[
                ]}
                options={{
                    search: false,
                }}
                actions={[
                    {icon: 'refresh', tooltip: 'Refresh', isFreeAction: true, onClick: refresh},
                ]}
            />
        </React.Fragment>
    )

}
