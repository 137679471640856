import React from 'react';

export default function Currency({centsUsd}) {
    const usd = centsUsd ? centsUsd / 100 : 0;
    const sValue = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'USD'
    }).format(usd);
    return <React.Fragment>{sValue}</React.Fragment>
}

