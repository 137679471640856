import React from 'react';
import * as R from 'react-router-dom';
import UriTemplate from 'uritemplate';

import {
    Button, makeStyles
} from '@material-ui/core';

import {url_for} from 'routes';

import {useAsyncMemo} from 'lib/util';
import {useApi} from 'components/Api';
import BasePage from 'components/BasePage';
import Order from 'components/Order';

const useStyles = makeStyles(theme => ({
    content: {
        padding: 16
    },
    hbox: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        margin: 4,
    }
}));


export default function OrderPage() {
    const classes = useStyles();
    const api = useApi();
    const {order_id} = R.useParams();
    const order = useAsyncMemo(async () => {
        const tpl = UriTemplate.parse(api.directory.data.links['checkout.order'])
        const order = await api.fetchJson(tpl.expand({id: order_id}))
        console.log('order', order);
        return order
    });

    return (
        <BasePage title="Order">
            <div className={classes.content}>
                <div className={classes.hbox}>
                    <Button component={R.Link} to={url_for('dashboard')} 
                        className={classes.button} variant="contained" color="primary">
                        View your lists
                    </Button>
                    <Button component={R.Link} to={url_for('orders')} 
                        className={classes.button} variant="contained" color="primary">
                        View your orders
                    </Button>
                </div>
                {order && <Order order={order}/>}
            </div>
        </BasePage>
    )
}
